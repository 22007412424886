"use strict";

/**
 * findByKey
 *
 * @todo This needs some minor refactoring :)
 *
 * @param {Object} nested Object to search
 * @param {String} needle Key to find
 * @return {Array}
 */
function findByKey(obj, key) {

  var j, key = key || '',
    obj = obj || {},
    keys = key.split("."),
    sObj = [],
    ssObj = [],
    isSelector = !!(keys.length > 0);

  var findKey = function(obj, key) {
    var k;
    for (k in obj) {
      if (k === key) {
        sObj.push(obj[k]);
      } else if (typeof obj[k] == 'object') {
        findKey(obj[k], key);
      }
    }
  };

  if (isSelector) {
    var nKey = keys.shift();
    findKey(obj, nKey);

    while (keys.length > 0) {
      nKey = keys.shift();

      if (sObj.length > 0) {
        ssObj = sObj.slice(0), sObj = [];
        for (j in ssObj) {
          findKey(ssObj[j], nKey);
        }
      }
    }
  } else {
    findKey(obj, key);
  }

  // return occurrences of key in array
  return (sObj.length === 1) ? sObj.pop() : sObj;
};

module.exports = findByKey;
